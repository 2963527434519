<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-01-06 08:14:25
 * @ Description: Component displaying the general profile industries/job titles tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Add Industry/Job Title Button -->
            <v-btn
                style="position: absolute; right: 16px; top: -92px;"
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA858"
                v-if="!showAddEditIndustryJobTitleBoolean"
                @click="showAddEditIndustryJobTitle(true)"
            >
                <span v-if="!$store.state.general.customBreakpoints.xxs">Add <span v-if="isCompany">Industry</span><span v-else-if="isIndividual === 'individual'">Title</span></span>
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
            <!-- Add Industry/Job Title Button -->

            <!-- Card View -->
            <v-row
                v-if="selectedView === 'card'"
            >
                <!-- No Industries/Job Titles -->
                <v-col
                    v-if="profileIndustryJobTitles === null || profileIndustryJobTitles.length === 0"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    No <span v-if="isCompany">industries</span><span v-else-if="isIndividual">job titles</span> added
                </v-col>
                <!-- No Industries/Job Titles -->

                <!-- Industries/Job Titles -->
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    v-for="(item, index) in profileIndustryJobTitles"
                    :key="index"
                    v-else
                >
                    <v-card
                        height="88px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                        v-if="isCompany"
                    >
                        <v-row
                            align="center"
                        >
                            <v-col
                                cols="6"
                                class="pb-0"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiDomain }}
                                </v-icon>
                            </v-col>
                            <v-col
                                cols="6"
                                class="text-right pb-0"
                            >
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                            >
                                                {{ mdiDotsHorizontal }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(menuItem, menuIndex) in menuItems"
                                            :key="menuIndex"
                                            @click="menuItemAction(menuItem, item)"
                                        >
                                            <v-list-item-title
                                                class="os-12-sb"
                                            >
                                                {{ menuItem }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col
                                class="os-16-sb text-center pt-0 text-truncate"
                                cols="12"
                            >
                                {{ item.industry.name }}
                            </v-col>
                            <!-- <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <div
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Job Title:
                                </div>
                                <div
                                    class="os-12-sb text-truncate"
                                    style="color: #2E567A"
                                >
                                    {{ item.job_title.name }}
                                </div>
                            </v-col> -->
                        </v-row>
                    </v-card>

                    <v-card
                        height="144px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                        v-else-if="isIndividual"
                    >
                        <v-row
                            align="center"
                        >
                            <v-col
                                cols="6"
                                class="pb-0"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiDomain }}
                                </v-icon>
                            </v-col>
                            <v-col
                                cols="6"
                                class="text-right pb-0"
                            >
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                            >
                                                {{ mdiDotsHorizontal }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(menuItem, menuIndex) in menuItems"
                                            :key="menuIndex"
                                            @click="menuItemAction(menuItem, item)"
                                        >
                                            <v-list-item-title
                                                class="os-12-sb"
                                            >
                                                {{ menuItem }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col
                                class="os-16-sb text-center pt-0"
                                cols="12"
                            >
                                {{ item.job_title.name }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <div
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Industry:
                                </div>
                                <div
                                    class="os-12-sb"
                                    style="color: #2E567A"
                                >
                                    {{ item.industry.name }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Industries/Job Titles -->
            </v-row>
            <!-- Card View -->

            <!-- List View -->
            <v-row
                v-if="selectedView === 'list'"
            >
                <!-- Search -->
                <v-col
                    cols="12"
                    class="pb-0 pt-10"
                >
                    <div
                        class="os-17-sb px-3"
                        style="background-color: white; height: 60px; display: flex; align-items: center;"
                    >
                        <span v-if="isCompany">All Industries</span>
                        <span v-else-if="isIndividual">All Job Titles</span>
                        <v-spacer />
                        <div
                            style="width: 275px;"
                        >
                            <v-text-field
                                dense
                                hide-details
                                outlined
                                color="#2E567A"
                                placeholder="Search..."
                                class="os-13-r"
                                background-color="white"
                                style="width: 275px;"
                                v-model="search"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="#C5CEE0"
                                        class="ma-0"
                                    >
                                        {{ mdiMagnify }}
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </v-col>
                <!-- Search -->

                <!-- Industries/Job Titles -->
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-data-table
                        :headers="industryJobTitlesHeaders"
                        :items="profileIndustryJobTitles"
                        item-key="id"
                        hide-default-footer
                        :no-data-text="noDataText"
                        :no-results-text="noResultsText"
                        :search="search"
                    >
                        <template v-slot:[`item.industry`]="{ item }">
                            {{ item.industry.name }}
                        </template>
                        <template v-slot:[`item.job_title`]="{ item }">
                            {{ item.job_title.name }}
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn
                                icon
                                @click="menuItemAction ('Edit', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiArrowRightThinCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <!-- <v-btn
                                icon
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiPrinterOutline }}
                                </v-icon>
                            </v-btn> -->
                            <v-btn
                                icon
                                @click="menuItemAction ('Delete', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiTrashCanOutline }}
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <!-- Industries/Job Titles -->
            </v-row>
            <!-- List View -->

            <!-- Add View -->
            <!-- Desktop -->
            <v-row
                v-if="showAddEditIndustryJobTitleBoolean && $vuetify.breakpoint.mdAndUp"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="9"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> <span v-if="isCompany">an Industry</span><span v-else-if="isIndividual">a Job Title</span>
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <!-- Heading -->

                        <!-- Industry/Job Title Form -->
                        <v-col
                            cols="7"
                        >
                            <v-form
                                v-model="validIndustryJobTitleForm"
                                ref="industryJobTitleForm"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="pb-0 os-15-sb"
                                    >
                                        Industry<span style="color: red;">*</span>
                                        <br />
                                        <v-autocomplete
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            return-object
                                            item-value="id"
                                            item-text="name"
                                            :items="industries"
                                            v-model="industryJobTitleToUpsert.industry"
                                            :rules="selectRules"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0 os-15-sb"
                                        v-if="isIndividual"
                                    >
                                        Job Title<span style="color: red;">*</span>
                                        <br />
                                        <v-autocomplete
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            return-object
                                            item-value="id"
                                            item-text="name"
                                            :items="jobTitles"
                                            v-model="industryJobTitleToUpsert.job_title"
                                            :rules="selectRules"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                        <!-- Industry/Job Title Form -->

                        <!-- Add/Edit/Cancel Buttons -->
                        <v-col
                            align-self="end"
                            class="pb-7"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="upsertIndustryJobTitle"
                                :disabled="!validIndustryJobTitleForm"
                            >
                                <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> <span v-if="isCompany">Industry</span><span v-else-if="isIndividual">Title</span>
                                <v-icon
                                    class="ml-1"
                                    v-if="action === 'add'"
                                >
                                    {{ mdiPlusCircleOutline }}
                                </v-icon>
                                <v-icon
                                    class="ml-1"
                                    v-else-if="action === 'edit'"
                                >
                                    {{ mdiCircleEditOutline }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="no-uppercase rounded-lg os-13-sb"
                                color="#8F9BB3"
                                @click="showAddEditIndustryJobTitle(false)"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <!-- Add/Edit/Cancel Buttons -->
                    </v-row>
                </v-col>
            </v-row>
            <!-- Desktop -->

            <!-- Mobile -->
            <v-row
                v-if="showAddEditIndustryJobTitleBoolean && $vuetify.breakpoint.smAndDown"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="12"
                >
                    <v-form
                        v-model="validIndustryJobTitleForm"
                        ref="industryJobTitleForm"
                    >
                        <v-row>
                            <!-- Industry/Job Title Form -->
                            <v-col
                                cols="12"
                                class="os-22-sb"
                            >
                                <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> <span v-if="isCompany">an Industry</span><span v-if="isIndividual">a Job Title</span>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                                :sm="isIndividual ? 6 : 12"
                                class="pb-0 os-15-sb"
                            >
                                Industry<span style="color: red;">*</span>
                                <br />
                                <v-autocomplete
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    return-object
                                    item-value="id"
                                    item-text="name"
                                    :items="industries"
                                    v-model="industryJobTitleToUpsert.industry"
                                    :rules="selectRules"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="os-15-sb"
                                :class="$vuetify.breakpoint.sm ? 'pb-0' : 'py-0'"
                                v-if="isIndividual"
                            >
                                Job Title<span style="color: red;">*</span>
                                <br />
                                <v-autocomplete
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    return-object
                                    item-value="id"
                                    item-text="name"
                                    :items="jobTitles"
                                    v-model="industryJobTitleToUpsert.job_title"
                                    :rules="selectRules"
                                />
                            </v-col>
                            <!-- Industry/Job Title Form -->

                            <!-- Add/Edit/Cancel Buttons -->
                            <v-col
                                cols="12"
                                class="text-right"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="upsertIndustryJobTitle"
                                    :disabled="!validIndustryJobTitleForm"
                                >
                                    <span v-if="!$store.state.general.customBreakpoints.xxs"><span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span></span>
                                    <v-icon
                                        class="ml-1"
                                        v-if="action === 'add'"
                                    >
                                        {{ mdiPlusCircleOutline }}
                                    </v-icon>
                                    <v-icon
                                        class="ml-1"
                                        v-else-if="action === 'edit'"
                                    >
                                        {{ mdiCircleEditOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    text
                                    class="no-uppercase rounded-lg os-13-sb"
                                    color="#8F9BB3"
                                    @click="showAddEditIndustryJobTitle(false)"
                                >
                                    Cancel
                                </v-btn>
                            </v-col>
                            <!-- Add/Edit/Cancel Buttons -->
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
            <!-- Mobile -->
            <!-- Add View -->
        </v-col>

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="itemToDeleteType"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->
    </v-row>
</template>
<script>
    import { mdiPlusCircleOutline, mdiDotsHorizontal, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiTrashCanOutline, mdiMagnify, mdiDomain, mdiCircleEditOutline } from '@mdi/js'
    import { GeneralProfileCategoryController, LookupIndustryController, LookupJobTitleController } from '@/logic/controller/index.controller.js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'

    export default {
        name: 'ProfileIndustriesJobTitlesTabComponent',

        components: {
            GeneralConfirmDeleteOverlayComponent
        },

        props: {
            selectedView: {
                type: String,
                required: true
            }
        },

        computed: {
            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            },

            noDataText () {
                if (this.isCompany) return 'No industries added'
                else return 'No job titles added'
            },

            selectRules () {
                return [
                    v => (!!v && !!v.id) || 'Required Field'
                ]
            },

            itemToDeleteType () {
                if (this.isCompany) return 'Industry'
                else return 'Job Title'
            },

            industryJobTitlesHeaders () {
                if (this.isIndividual) {
                    return [
                        {
                            text: 'Industry',
                            value: 'industry.name'
                        },
                        {
                            text: 'Title',
                            value: 'job_title.name'
                        },
                        {
                            text: 'Action',
                            value: 'action'
                        }
                    ]
                } else {
                    return [
                        {
                            text: 'Industry',
                            value: 'industry.name'
                        },
                        {
                            text: 'Action',
                            value: 'action'
                        }
                    ]
                }
            }
        },

        data () {
            return {
                mdiPlusCircleOutline,
                mdiDotsHorizontal,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiTrashCanOutline,
                mdiMagnify,
                mdiDomain,
                mdiCircleEditOutline,
                showAddEditIndustryJobTitleBoolean: false,
                profileIndustryJobTitles: [],
                menuItems: [
                    'Edit',
                    // 'Print',
                    'Delete'
                ],
                generalProfileCategoryController: null,
                lookupIndustryController: null,
                lookupJobTitleController: null,
                industries: [],
                jobTitles: [],
                action: 'add',
                industryJobTitleToUpsert: {
                    industry: {},
                    job_title: {}
                },
                validIndustryJobTitleForm: false,
                search: '',
                showDeleteConfirmationOverlayBoolean: false,
                industryToDelete: null,
                isCompany: false,
                isIndividual: false
            }
        },

        beforeMount () {
            this.generalProfileCategoryController = new GeneralProfileCategoryController()
            this.lookupIndustryController = new LookupIndustryController()
            this.lookupJobTitleController = new LookupJobTitleController()
        },

        async mounted () {
            if (this.$store.state.user.userContext === 'company') this.isCompany = true
            else if (this.$store.state.user.userContext === 'individual') this.isIndividual = true

            await this.getIndustries()
            await this.getJobTitles()
            await this.getProfileIndustryJobTitles()
        },

        methods: {
            async getIndustries () {
                try {
                    this.industries = await this.lookupIndustryController.get(false, false, false, false, this.isIndividual, this.isCompany)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobTitles () {
                try {
                    this.jobTitles = await this.lookupJobTitleController.get(false, false, false, false, this.isIndividual, this.isCompany)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfileIndustryJobTitles () {
                try {
                    this.profileIndustryJobTitles = await this.generalProfileCategoryController.retrieve(this.$store.state.user.userData.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async upsertIndustryJobTitle () {
                try {
                    if (this.isCompany) this.industryJobTitleToUpsert.job_title.id = 1

                    this.profileIndustryJobTitles = await this.generalProfileCategoryController.upsert(this.industryJobTitleToUpsert)

                    if (this.action === 'edit') {
                        this.$store.commit('setSnackbar', {
                            text: 'Updated successfully',
                            show: true,
                            color: '#069B34'
                        })
                    }

                    this.showAddEditIndustryJobTitleBoolean = false
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmDeleteIndustryJobTitle (industry) {
                this.industryToDelete = industry
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteIndustryJobTitle (industry) {
                try {
                    this.profileIndustryJobTitles = await this.generalProfileCategoryController.delete(industry)

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.industryToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showAddEditIndustryJobTitle (value) {
                this.showAddEditIndustryJobTitleBoolean = value
                this.action = 'add'

                this.industryJobTitleToUpsert = {
                    industry: {},
                    job_title: {}
                }
            },

            menuItemAction (action, item) {
                if (action === 'Edit') {
                    Object.assign(this.industryJobTitleToUpsert, item)
                    this.action = 'edit'
                    this.showAddEditIndustryJobTitleBoolean = true
                } else if (action === 'Delete') this.confirmDeleteIndustryJobTitle(item)
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteIndustryJobTitle(this.industryToDelete)
            }
        }
    }
</script>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }

    .radio-text /deep/ label {
        color: black !important;
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }
</style>
